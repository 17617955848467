/* App.css or your global CSS file */


/*this is new*/

:root {
  --accent-color: #ff0000;
  --accent-glow: rgba(255, 0, 0, 0.8);
  --accent-glow2: rgba(255, 0, 0, 0.4);
}

h1 {
  color: var(--accent-color);
  text-shadow: 0px 0px 20px var(--accent-glow);
}

button {
  color: var(--accent-color);
  text-shadow: 0px 0px 20px var(--accent-glow);
}

.bar-box {
  border: 4px solid var(--accent-color);
  box-shadow: 0px 0px 20px var(--accent-glow);
}

.border-fill {
  background-color: var(--accent-color);
}

.password-input {
  caret-color: var(--accent-color);
  color: var(--accent-color);
  text-shadow: 0px 0px 20px var(--accent-glow);
}

p {
  background-color: var(--accent-color);
}


.p-red {
  color: #ff0000;
  background-color: black;
  
}

.p-pink {
  color: #BD00FF;
  background-color: black;
}

.p-white {
  color: white;
  background-color: black;
  opacity: 0.3;
}

/*new ends */





@font-face {
  font-family: 'OldEnglishGothicPixelRegular';
  src: url('./fonts/OldEnglishGothicPixelRegular-gx1jp.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pixelon';
  src: url('./fonts/Pixelon-OGALo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  background-color: black;
  letter-spacing: 0.25em;
  font-family: 'OldEnglishGothicPixelRegular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: var(--accent-color);
  text-shadow: 0px 0px 20px var(--accent-glow);
  font-size: 2em;
  font-weight: normal;
  text-align: center;
}

button {
  font-family: 'OldEnglishGothicPixelRegular', sans-serif;
  border: none;
  background-color: black;
  color: var(--accent-color);
  cursor: pointer;
  font-size: 2em;
  text-shadow: 0px 0px 20px var(--accent-glow);
}

button:hover {
  background-color: var(--accent-color);
  color: black;
}


.button2 {
  font-family: 'Pixelon', sans-serif;
  cursor: pointer;
  font-size: 1.5em;
  background-color: #BD00FF;
  color: black;
  
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s ease-in-out 3s forwards; /* 3s delay before appearing */
  width: fit-content;
}

.button3 {
  font-family: 'Pixelon', sans-serif;
  cursor: pointer;
  font-size: 1.5em;
  background-color: #BD00FF;
  color: black;
  
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s ease-in-out 2s forwards; /* 3s delay before appearing */
  width: fit-content;
}

.button3:hover{
  background-color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.button2:hover {
  background-color: #ffffff;
  color: black;
}



.dialog_shape{
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  align-items: left;
  padding-left: 16px;
  padding-right: 16px;
}

.lh{
line-height: 150%;
}

.button_space{
  padding-top: 6px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 6em;
}

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 2em;
  margin-top: -20px;
}


.password-container2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 2em;
  margin-top: -20px;
}

.password-title {
  font-size: 2em;
  text-shadow: 0px 0px 20px var(--accent-glow);
}

.password-input {
  display: inline-block;
  height: fit-content;
  font-size: 24px;
  margin-top: 20px;
  border: none;
  outline: none;
  background: none;
  caret-color: var(--accent-color);
  color: var(--accent-color);
  padding: 0;
  min-width: 30px;
  white-space: nowrap;
  font-family: 'OldEnglishGothicPixelRegular', sans-serif;
  text-align: center;
  font-size: 6em;
  text-shadow: 0px 0px 20px var(--accent-glow);
}

.bar-box {
  background-color: transparent;
  width: 30vw;
  height: 1em;
  border: 4px solid var(--accent-color);
  display: flex;
  align-items: center;
  padding: 6px;
  box-shadow: 0px 0px 20px var(--accent-glow2);
}


.bar-box2 {
  background-color: transparent;
  width: 30vw;
  height: 1em;
  border: 4px solid #BD00FF;
  display: flex;
  align-items: center;
  padding: 6px;
  box-shadow: 0px 0px 20px rgba(206, 0, 255, 0.4);
}


.purple{
  background-color: #BD00FF;
  box-shadow: 0px 0px 20px rgba(206, 0, 255, 0.4);
}

.border-fill {
  background-color: var(--accent-color);
  height: 100%;
  width: 0;
  animation: fill 10s linear forwards;
}

.border-fill-con {
  background-color: var(--accent-color);
  height: 100%;
  width: 0;
  animation: fill2 5s linear forwards;
}

@keyframes fill2 {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}



.border-fill2 {
  background-color: #BD00FF;
  height: 100%;
  width: 0;
  animation: fill 10s linear forwards;
}


.loading-title2{
color: #BD00FF;
text-shadow: 0px 0px 20px rgba(206, 0, 255, 0.4);
}


.animated-video {
  height: 100vh;  /* Full viewport height */
  width: 100vw;   /* Full viewport width */
  object-fit: cover; /* Ensures the video covers the area without distortion */
}

.play{
  text-align: center;
}



@keyframes fill {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.back-button {
  position: absolute;
  top: 2em;
  left: 2em;
}

p {
  color: rgb(0, 0, 0);
  background-color: var(--accent-color);
  font-size: 1em;
  padding: 4px;
  font-family: 'Pixelon';
  letter-spacing: 0;
  font-weight: bold;
}

@keyframes revealText {
  from {
    clip-path: inset(0 100% 0 0); /* Completely hidden */
  }
  to {
    clip-path: inset(0 0 0 0); /* Fully revealed */
  }
}

p2 {
  color: #BD00FF;
  background-color: #000000;
  font-size: 1.3em;
  padding: 4px;
  font-family: 'Pixelon';
  letter-spacing: 0;
  font-weight: bold;
  display: inline-block;
  overflow: hidden; /* Prevents overflow */
  clip-path: inset(0 100% 0 0); /* Initially hidden */
  animation: revealText 1.5s linear forwards;
}

/* Delay for second line */
p2.lh {
  animation-delay: 0.5s; /* Starts after the first line */
}

p2.lh2{
  animation-delay: 2s; /* Starts after the first line */
}

.frame_dialog{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
 
}

.animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.animated-image {
  max-width: 100%;
  max-height: 100%;
  transition: opacity 0.1s ease-in-out;

}

.svg-guy {
  width: 4em;
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

/* Apply the hover effect on the parent .svg-button */
.svg-button:hover .svg-guy {
  transform: translateY(-10px); /* Move up on hover */
}


.svg-guy2 {
  width: 100px;
  transition: transform 0.3s ease; /* Smooth transition for movement */
}


.svg-guy3 {
  width: 24px;
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

.svg-guy4 {
  width: 32px;
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

.lock-icon {
  width: 24px;
  opacity: 0.3;
}



.surround {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textandicon{
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  width: 220px;
}

.levels{
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 2px solid white; /* Adjust thickness, style, and color as needed */
  padding: 24px;
}

/* Apply the hover effect on the parent .svg-button */
.svg-button:hover .svg-guy2 {
  transform: translateY(-10px); /* Move up on hover */
}

/* Media Query for small screens (max-width: 768px) */

@media (min-width: 443px){
  .frame_dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    border: 2px solid #BD00FF; /* Adjust thickness, style, and color as needed */
    width: 400px;
   
  }
}

@media (max-width: 768px) {
  .password-input {
    font-size: 5em;
  }

  .password-title {
    font-size: 2em;
  }

  .back-button {
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .bar-box {
    width: auto;
  }
  

  .bar {
    width: 100%;
  }

  h1 {
    display: flex;
    align-items: center;
  }

  .animated-image {
    height: calc(100vh - 20px);
    width: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the screen while maintaining aspect ratio */
  }


  body {
    background-color: black;
    letter-spacing: 0.25em;
    font-family: 'OldEnglishGothicPixelRegular', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px; /* Adds space at the top */
}

.svg-guy {
  width: 4em;
  transition: transform 0.3s ease; /* Smooth transition for movement */
  
}

.back-button {
  position: absolute;
  left: 1em;
}



 
}

